import CustomersCountCard from '@/components/dashboard/CustomersCountCard';
import { PremiumCard } from '@/components/dashboard/PremiumCard';
import SubscriptionsCountCard from '@/components/dashboard/SubscriptionsCountCard';
import AuthGuard from '@/components/guards/AuthGuard';
import StoresGuard from '@/components/guards/StoresGuard';
import ShellLayout from '@/components/layouts/ShellLayout';
import PageTitle from '@/components/ui/PageTitle';
import { useUserContext } from '@/contexts/user-context';
import { Section } from '@/utils/access/user-access';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateSelector, SelectableDates } from '@/components/ui/charts/DateSelector';
import { subMonths } from 'date-fns';
import SalesList from '@/components/dashboard/SalesList';
import { useQuery } from '@tanstack/react-query';
import api from '@/core/client';
import { useForm } from 'react-hook-form';
import ProductsSalesCard from '@/components/dashboard/ProductsSalesCard';
import SelectFieldBase from '@/components/forms/formFields/SelectFieldBase';

const Dashboard = () => {
  const { t } = useTranslation(['common', 'dashboard', 'subscription', 'product']);
  const { hasAccessTo, activeStore } = useUserContext();

  const [selectedStartDate, setSelectedStartDate] = useState<string | undefined>(
    subMonths(new Date(), 12).toISOString(),
  );
  const [selectedEndDate, setSelectedEndDate] = useState<string | undefined>(
    new Date().toISOString(),
  );
  const [selectedStoreIds, setSelectedStoreIds] = useState<string[]>();
  const { data: selectableStores } = useQuery({
    queryKey: ['allChildrenStores', activeStore?.id],
    queryFn: () => api.stores.getAllChildrenStores(activeStore!.id),
    enabled: !!activeStore?.id,
    select: (data) => [activeStore!, ...data],
  });

  const methods = useForm<{ selectedStoreIds?: string[] }>({
    defaultValues: {
      selectedStoreIds: undefined,
    },
  });

  useEffect(() => {
    if (selectableStores && activeStore?.id) {
      setSelectedStoreIds(selectableStores.map((store) => store.id));
    }
  }, [selectableStores, methods, activeStore?.id]);

  const handleDateChange = (startDate?: string, endDate?: string) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  return (
    <>
      <PageTitle
        label={t('common:dashboard')}
        rightContent={
          <Flex flexWrap="wrap" gap={3} alignItems="center">
            <SelectFieldBase
              variant="plain"
              value={selectedStoreIds}
              size="sm"
              width={170}
              isMulti
              name="selectedStoreIds"
              onChange={(values) => {
                setSelectedStoreIds(values);
              }}
              options={(selectableStores || []).map((store) => ({
                label: store.name,
                value: store.id,
              }))}
              placeholder={t('common:stores')}
            />
            <DateSelector
              onChange={handleDateChange}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              defaultSelected={SelectableDates.LAST_12_MONTHS}
            />
          </Flex>
        }
      />

      <Grid templateColumns={'repeat(12, 1fr)'} gap={10} mt={8} mb={8} alignItems="stretch">
        {hasAccessTo(Section.DASHBOARD) && (
          <>
            <GridItem colSpan={{ base: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
              <SubscriptionsCountCard
                activeStore={activeStore}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                selectedStoreIds={selectedStoreIds}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
              <ProductsSalesCard
                activeStore={activeStore}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                selectedStoreIds={selectedStoreIds}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 6, xl: 4 }}>
              <CustomersCountCard
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                activeStore={activeStore}
                selectedStoreIds={selectedStoreIds}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 6, xl: 12 }}>
              <PremiumCard
                activeStore={activeStore}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                selectedStoreIds={selectedStoreIds}
              />
            </GridItem>
          </>
        )}

        <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <SalesList />
        </GridItem>
      </Grid>
    </>
  );
};

Dashboard.getLayout = function getLayout(page: ReactElement) {
  return (
    <AuthGuard>
      <StoresGuard>
        <ShellLayout>{page}</ShellLayout>
      </StoresGuard>
    </AuthGuard>
  );
};

export default Dashboard;
